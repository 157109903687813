import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Navbar from './Navbar'
import { Box, Anchor } from 'grommet'

const Footer = styled(Box)`
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 8px;
`

const AppWrapper = ({ children, size = "xlarge" }) => {
  return (
    <Box fill >
      <Navbar />
      <Box
        background="white"
        flex={{grow: 3}}
        style={{zIndex: 10}}
        width={size}
        elevation="medium"
        
        alignSelf='center'
        pad={{horizontal: "large", bottom: "large", top: "medium"}}>
        {children}
      </Box>
      <Footer> 
        <Anchor 
          size="xsmall"
          href="https://www.donodoo.it"
          target="_blank">
            Powered by 
            Donodoo
          </Anchor>
      </Footer>
    </Box>
  )
}

AppWrapper.propTypes = {
  size: PropTypes.string
}

export default React.memo(AppWrapper)