import React from "react"
import { useParams } from "react-router"
import { Box, ResponsiveContext } from "grommet"
import AppWrapper from "../../components/App/AppWrapper"
import Breadcrums from "../../components/App/Breadcrums"
import TicketSidebar from "../../components/Tickets/TicketSidebar"
import TicketContent from "../../components/Tickets/TicketContent"
import TaskBox from "../../components/Tasks/TaskBox"
import MessageBox from "../../components/Messages/MessageBox"
import NothingHere from "../../components/Common/NothingHere"
import Spinner from "../../components/Common/Spinner"
import { useTranslation } from "react-i18next"
import { useTicket } from "../../hooks/tickets"

const TicketPage = () => {
  const { t } = useTranslation()
  const { ticketId } = useParams()

  const {data: ticket, isLoading, isFetched } = useTicket(ticketId)

  return (
    <AppWrapper size="xxlarge">
      {isLoading ? (
        <Spinner/>
      ) : isFetched && ticket ? (
        <Box direction="row">
          <Box fill pad={{ right: "medium" }}>
            <Breadcrums queueId={ticket.queue_slug} ticketId={ticket.id} />

            <TicketContent ticket={ticket} />

            <ResponsiveContext.Consumer>
              {size =>
                size === "small" && (
                  <Box basis="1/4">
                    <TicketSidebar ticket={ticket} />
                  </Box>
                )
              }
            </ResponsiveContext.Consumer>

            <TaskBox ticket={ticket} />
            <MessageBox ticket={ticket} />
          </Box>
          <ResponsiveContext.Consumer>
            {size =>
              size !== "small" && (
                <Box basis="1/4">
                  <TicketSidebar ticket={ticket} />
                </Box>
              )
            }
          </ResponsiveContext.Consumer>
        </Box>
      ) : (
        <NothingHere
          title={t("NotFound.NothingHere")}
          message={t("NotFound.NotWhatYouAreLookingFor")}
        />
      )}
    </AppWrapper>
  )
}

export default React.memo(TicketPage)
