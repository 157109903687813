import _keys from 'lodash/keys'

export const HOME = '/';
export const LOGIN = '/login';
export const OAUTH = '/oauth';
export const PROFILE = '/profile';

export const QUEUE_DETAIL = '/:queueId'
export const QUEUE_TICKETS = '/:queueId/tickets'

export const ALL_TICKETS = '/tickets'
export const TICKET_CREATE = '/:queueId/tickets/create/:typeId'
export const TICKET_DETAILS = '/:queueId/tickets/:ticketId'
export const TICKET_ATTACHMENTS = '/:queueId/tickets/:ticketId/attachments/:attachmentId'

export const toQueryString = object =>
  '?' +
  Object.keys(object)
    .filter(key => object[key])
    .map(key => `${key}=${object[key].toString()}`)
    .join('&');


export const getRoute = (path, args, params = {}) => {
  let dest = _keys(args).reduce((acc, cur) => acc.replace(`:${cur}`, args[cur]), path)
  if(Object.entries(params).length !== 0 && params.constructor === Object) {
    dest = `${dest}${toQueryString(params)}`

  }
  return dest
}