import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next'
import { Box, Text, Drop } from "grommet"
import styled from "styled-components"
import PriorityIcon from "../PriorityIcon"
import { useQueuesById } from "../../../hooks/queues"

const PriorityBox = styled(Box)`
  cursor: pointer
`

const TicketPriorityForm = ({ ticket, onChange }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [target, setTarget] = React.useState()
  const targetRef = React.useCallback(setTarget, [])
  const queue = useQueuesById()[ticket.queue_id]

  const handleChange = stage => {
    onChange(stage)
    setOpen(false)
  }

  const PRIORITIES = [
    { value: "0", label: t("Ticket.PriorityLow") },
    { value: "1", label: t("Ticket.PriorityMedium") },
    { value: "2", label: t("Ticket.PriorityHigh") },
    { value: "3", label: t("Ticket.PriorityBlocking") }
  ]

  return (
    <>
      <PriorityBox ref={targetRef} onClick={() => setOpen(!open)}>
        <PriorityIcon priority={ticket.priority} />
      </PriorityBox>
      {open && target && queue && (
        <Drop
          onClickOutside={() => setOpen(false)}
          onEsc={() => setOpen(false)}
          align={{ top: "bottom", left: "left" }}
          target={target}
        >
          {PRIORITIES.filter(s => s.value !== ticket.priority).map(s => (
            <PriorityBox
              direction="row"
              align="center"
              onClick={() => handleChange(s.value)}
              pad="small"
              gap="small"
              key={`stage_${s.value}`}
            >
              <PriorityIcon priority={s.value} />
              <Text>{s.label}</Text>
            </PriorityBox>
          ))}
        </Drop>
      )}
    </>
  )
}

TicketPriorityForm.propTypes = {
  ticket: PropTypes.object,
  onChange: PropTypes.func
}

export default React.memo(TicketPriorityForm)
