import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { getRoute } from "../navigation/routes"

export function useRouter() {
  const history = useHistory()
  const push = useCallback(
    (route, params = {}) => {
      const path = getRoute(route, params)
      history.push(path)
    },
    [history]
  )

  return {
    push,
  }
}
