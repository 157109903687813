import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading } from 'grommet'
import AttachmentList from '../../components/Attachments/AttachmentList'
import Followers from './Followers'
import TicketStatusForm from './Details/TicketStatusForm'
import TimesOverview from './Details/TimesOverview'
import { useTicketMutations } from '../../hooks/tickets'
import { useFollowers } from '../../hooks/followers'
import { useAttachments } from '../../hooks/attachments'

const TicketSidebar = ({ticket}) => {
  const attachments = useAttachments(ticket.id)
  const followers = useFollowers(ticket.id)

  const { update } = useTicketMutations()

  const handleStatusChange = (stage) => {
    update.mutate({ticketId: ticket.id, data: { stage_id: stage.id }})
  }


  return (
    <Box>
      <TicketStatusForm loading={update.isLoading} current={ticket.stage} ticket={ticket} onChange={handleStatusChange}/>

      <TimesOverview ticket={ticket}/>

      <Followers ticket={ticket} followers={followers.data || []}/>
      {attachments && (
        <>
          <Heading level="5">Allegati</Heading>
          <AttachmentList 
            size={null} 
            basis="1/3" 
            attachments={attachments.data || []}/>
        </>
      )}
    </Box>
  )
}

TicketSidebar.propTypes = {
  ticket: PropTypes.object
}

export default React.memo(TicketSidebar)