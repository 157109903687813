import _keys from "lodash/keys"
import _keyBy from "lodash/keyBy"
import React, {useEffect, useState} from "react"
import { Box, Button, Text } from "grommet"
import { useCurrentQueue } from "../../../hooks/queues"
import { useUsers } from "../../../hooks/users"
import { Close } from 'grommet-icons'
import { useTranslation } from "react-i18next"

function FilterItemState({label, value}) {
  const { t } = useTranslation()
  const [stages, setStages] = useState(null)
  const queue = useCurrentQueue()

  useEffect(() => {
    if(queue) {
      setStages(_keyBy(queue.stage_ids, 'id'))
    }
  }, [queue])

  return stages && value.length ? (
    <Box direction="row" align="center">
      <Text size="small" pad={{right: "small"}}>{t("Ticket.State")}:</Text>
      <Text size="small">{value.map(stageId => stages[stageId].name).join(',')}</Text>
    </Box>
  ) : null
}

function FilterItemQuery({label, value}) {
  const { t } = useTranslation()
  return (
    <Box direction="row" align="center">
      <Text size="small" pad={{right: "small"}}>{t("Ticket.Query")}:</Text>
      <Text size="small">{value}</Text>
    </Box>
  )
}

function FilterItemCreatedBy({label, value}) {
  const { t } = useTranslation()
  const [names, setNames] = useState(null)
  const users = useUsers()

  useEffect(() => {
    if(users.data) {
      setNames(_keyBy((users.data || []), 'partner_id'))
    }
  }, [users.data])

  return names && value.length && (
    <Box direction="row" align="center">
      <Text size="small" pad={{right: "small"}}>{t("Ticket.CreatedBy")}:</Text>
      <Text size="small">{value.map(uid => names[uid].name).join(',')}</Text>
    </Box>
  )
}

const FILTERS_WIDGETS = {
  'state': FilterItemState,
  'created_by': FilterItemCreatedBy,
  'query': FilterItemQuery
}

function FilterItem({label, value, onCancel}) {
  if(!value) {
    return null
  }

  const ItemComponent = FILTERS_WIDGETS[label]

  return value && ItemComponent ? (
    <Box pad="small" background="light-2" direction="row" gap="small">
      <ItemComponent label={label} value={value}/>
      <Button onClick={() => onCancel(label)}><Close size="small"/></Button>
    </Box>
  ) : null
}

function FilterList({ filters, onChange }) {
  const handleCancel = (key) => {
    const newFilters = {
      ...filters,
      [key]: undefined
    }
    onChange(newFilters)
  }

  return (
    filters && (
      <Box direction="row" pad={{ top: "medium" }} gap="small">
        {_keys(filters)
          .filter((key) => filters[key])
          .map((key) => (
            <FilterItem key={key} label={key} value={filters[key]} onCancel={handleCancel}/>
          ))}
      </Box>
    )
  )
}

export default FilterList
