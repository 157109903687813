import { useMutation, useQuery, useQueryClient } from "react-query"
import api from "../data/network"
import { readFile } from "./attachments"

export function useMessages(ticketId) {
  const messages = useQuery(
    ["messages", ticketId],
    async () => {
      const response = await api.messages.search({ ticket: ticketId })
      return response.result
    },
    {
      enabled: !!ticketId,
    }
  )

  return messages
}

export function useMessagesMutations() {
  const queryClient = useQueryClient()
  // add
  const add = useMutation(
    async (payload) => {
      return new Promise(async (resolve, reject) => {
        const { files, ...data } = payload
        try {
          const attachment_ids = []
          for (let file of files) {
            const fileData = {
              name: file.name,
              ticket_id: data.ticket_id,
              mimetype: file.type,
            }
            fileData["content"] = await readFile(file)
            const a = await api.attachments.create(fileData)
            attachment_ids.push(a.id)
          }
          data.attachment_ids = attachment_ids
          const message = await api.messages.create(data)
          resolve(message)
        } catch (e) {
          reject(e)
        }

      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("messages", { active: true })
      },
    }
  )
  return { add }
}
