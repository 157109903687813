import { useQuery } from "react-query"
import api from '../data/network'

export function useUsers() {
  const results = useQuery(['users'], async() => {
    const response = await api.users.search()
    return response.result || []
  })

  return results
}

