import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import * as routes from "../../navigation/routes"
import Avatar from "react-avatar"

import { Box, Heading, Menu, Image, Text, ResponsiveContext } from "grommet"
import logo from "../../assets/images/logo_square.png"
import { Logout } from "grommet-icons"
import { useCurrentQueue, useQueues } from "../../hooks/queues"
import { useAuth } from "../../hooks/auth"
import { useRouter } from "../../hooks/router"

const BackgroundBox = styled(Box)`
  top: 0,
  left: 0,
  padding: 50px,
  width: 50%,
`

const BrandBox = styled(Box)`
  cursor: pointer;
`

const Navbar = () => {
  const { t } = useTranslation()
  const [queueItems, setQueueItems] = useState([])
  const { user } = useAuth()
  const { logout } = useAuth()
  const { push } = useRouter()

  const { data: queues, isLoading } = useQueues()
  const queue = useCurrentQueue()

  const menuItems = [
    {
      label: t("App.Logout"),
      gap: "large",
      icon: <Logout style={{ marginRight: 16 }} />,
      onClick: logout,
    },
  ]

  useEffect(() => {
    if (!isLoading) {
      setQueueItems(
        queues
          .filter((q) => !queue || queue.id !== q.id)
          .map((q) => ({
            label: q.name,
            onClick: () => push(routes.QUEUE_TICKETS, { queueId: q.slug }),
          }))
      )
    }
  }, [isLoading, queues, queue, setQueueItems, push])

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box>
          <Box
            tag="header"
            direction="row"
            align="center"
            justify="between"
            background="brand"
            pad={{ left: "medium", right: "small", vertical: "medium" }}
            style={{ zIndex: "1" }}
          >
            <Box direction="row" align="center" gap="medium">
              <BrandBox
                onClick={() => push(routes.HOME)}
                direction="row"
                gap="small"
                align="center"
              >
                <Image height={40} fit="contain" src={logo} />
                {size !== "small" && (
                  <Heading align="center" level="3" margin="none">
                    {t("App.Support")}
                  </Heading>
                )}
              </BrandBox>

              <Menu
                label={queue ? queue.name : t("Ticket.AllTickets")}
                items={queueItems}
              />
            </Box>

            <Menu
              label={
                <Box direction="row" gap="small" align="center">
                  <Avatar
                    size="40px"
                    round
                    email={user.email}
                    src={user.image}
                    name={user.name}
                  />
                  {size !== "small" && <Text>{user.name}</Text>}
                </Box>
              }
              items={menuItems}
            />
          </Box>
          <BackgroundBox
            style={{ position: "absolute", width: "100%" }}
            background="brand"
            height="medium"
          />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default React.memo(Navbar)
