import React from "react"
import PropTypes from "prop-types"
import { Box, Heading, Text, Anchor } from "grommet"
import AddFollowersForm from "./AddFollowersForm"
import Avatar from "react-avatar"
import { useTranslation } from "react-i18next"
import { useFollowersMutations } from "../../hooks/followers"

const Followers = ({ ticket, followers }) => {
  const { t } = useTranslation()
  const { add, remove } = useFollowersMutations()

  function handleSubmit(partnerId) {
    add.mutate({ ticketId: ticket.id, partnerId })
  }

  function handleRemove(followerId) {
    remove.mutate(followerId)
  }

  return followers ? (
    <Box>
      {ticket.assignee_id && (
        <>
          <Heading level="5">In carico</Heading>
          <Box direction="row" gap="small">
            <Avatar
              size="40px"
              round
              email={ticket.assignee_email}
              name={ticket.assignee_name}
            />
            <Box margin={{ bottom: "small" }}>
              <Text size="medium">{ticket.assignee_name}</Text>
            </Box>
          </Box>
        </>
      )}
      <Heading level="5">{t("Ticket.AreFollowing")}</Heading>
      {followers.map(follower => (
        <Box direction="row" gap="small" key={follower.email}>
          <Avatar
            size="40px"
            round
            email={follower.email}
            name={follower.name}
          />

          <Box key={follower.email} margin={{ bottom: "small" }}>
            <Text size="medium">{follower.name}</Text>
            {follower.partner_id === ticket.author_id ? (
              <Text color="gray" size="small" weight="bold">
                {t("Ticket.Author")}
              </Text>
            ) : (
              <Anchor size="small" onClick={() => handleRemove(follower.id)}>
                {t("Ticket.Remove")}
              </Anchor>
            )}
          </Box>
        </Box>
      ))}
      <AddFollowersForm
        onSubmit={handleSubmit}
        omit={followers.map(f => f.partner_id)}
      />
    </Box>
  ) : null
}

Followers.propTypes = {
  ticket: PropTypes.object,
  followers: PropTypes.array
}

export default React.memo(Followers)
