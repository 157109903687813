import React from "react"
import { Form, Button, Box } from "grommet"
import { Formik } from "formik"
import InputField from "../Forms/InputField"
import { useTranslation } from "react-i18next"
import i18n from '../../locales'
import { useQueryParam, StringParam } from 'use-query-params';


const LoginForm = ({ onSubmit, submitting }) => {
  const { t } = useTranslation()
  const [next] = useQueryParam('next', StringParam);


  return (
    <Formik
      initialValues={{ email: "", password: "", next }}
      validate={validate}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <Form onSubmit={handleSubmit}>
          <InputField
            type="email"
            name="email"
            placeholder={t("Login.YourEmailAddress")}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email ? errors.email : null}
            value={values.email}
          />

          <InputField
            type="password"
            name="password"
            placeholder={t("Login.YourPassword")}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password && touched.password ? errors.password : null}
            value={values.password}
          />
          <Box>

            <Button
              disabled={submitting}
              pad="small"
              type="submit"
              primary
              label={t("Login.Login")}
            />
          </Box>
        </Form>
      )}
    />
  )
}

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = i18n.t("Error.EmailRequired")
  }

  if (!values.password) {
    errors.password = i18n.t("Error.PasswordRequired")
  }

  return errors
}

export default React.memo(LoginForm)
