import React from "react"
import { getRoute, QUEUE_DETAIL } from "../../navigation/routes"
import { useTranslation } from "react-i18next"
import { Heading, Box, Paragraph } from "grommet"
import { useTicketFilters, useTickets } from "../../hooks/tickets"
import { useCurrentQueue, useQueues } from "../../hooks/queues"
import AppWrapper from "../../components/App/AppWrapper"
import TicketTable from "../../components/Tickets/List/TicketTable"
import Breadcrums from "../../components/App/Breadcrums"
import LinkButton from "../../components/Common/LinkButton"
import Spinner from "../../components/Common/Spinner"
import FilterBar from "../../components/Tickets/Filters/FilterBar"
import FilterList from "../../components/Tickets/Filters/FilterList"

const TicketsListPage = () => {
  const { t } = useTranslation()
  const queue = useCurrentQueue()

  const tickets = useTickets()
  const queues = useQueues()

  const { filters, setFilters } = useTicketFilters()

  
  return filters && (
    <AppWrapper size="xxlarge">
      {queues.data.length > 0 ? (
        <React.Fragment>
          <Breadcrums />
          <Box direction="row" justify="between">
            <Heading level="3">{t("Ticket.Requests")}</Heading>
            <Box>
              <LinkButton
                to={getRoute(QUEUE_DETAIL, { queueId: queue.slug })}
                label={t("Ticket.NewRequest")}
              />
            </Box>
          </Box>

          {filters && <FilterBar filters={filters} onChange={setFilters} />}
          {filters && <FilterList filters={filters} onChange={setFilters} />}

          {tickets.isLoading ? (
            <Spinner />
          ) : tickets.data && tickets.data.length > 0 ? (
            <TicketTable tickets={tickets.data} />
          ) : (
            <Box margin="medium" align="center">
              <Heading margin={{ bottom: "none" }} level="2">
                {t("Ticket.ThereAreNoTickets")}
              </Heading>
              <Paragraph>{t("Ticket.CantFindAnyTicketHere")}</Paragraph>
              <LinkButton
                primary
                to={getRoute(QUEUE_DETAIL, { queueId: queue.slug })}
                label={t("Ticket.NewRequest")}
              />
            </Box>
          )}
        </React.Fragment>
      ) : null}
    </AppWrapper>
  )
}

export default React.memo(TicketsListPage)
