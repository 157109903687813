let backendHost;

const hostname = window && window.location && window.location.hostname;

if(hostname !== 'localhost') {
  backendHost = 'https://workplace.metadonors.it';
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost';
}

export const HOST = backendHost
