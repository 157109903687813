import React from "react"
import { Box, Heading, Paragraph } from "grommet"
import { HOME } from '../../navigation/routes';
import LinkButton from './LinkButton';

const NothingHere = ({ title, message }) => {
  return (
    <Box align="center" justify="center" margin="xlarge">
      <Heading margin={{ bottom: "none" }}>{title}</Heading>
      <Paragraph>{message}</Paragraph>
      <Box align="center">
        <LinkButton primary to={HOME} label="Torna alla homepage" />
      </Box>
    </Box>
  )
}

export default React.memo(NothingHere)