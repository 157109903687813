import React from "react"
import { Box } from "grommet"
import { useTranslation } from "react-i18next"

import LinedTitle from "../Common/LinedTitle"
import TaskList from "./TaskList"
import { useTasks } from "../../hooks/tasks"


const TaskBox = ({ ticket }) => {
  const tasks = useTasks(ticket.id)
  const { t } = useTranslation()

  return (
    !tasks.isLoading &&
    tasks.data.length > 0 && (
      <Box animation="fadeIn">
        <LinedTitle title={t("Tasks.Tasks")} />
        <TaskList tasks={tasks.data} />
      </Box>
    )
  )
}

export default React.memo(TaskBox)
