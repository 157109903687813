import React from 'react'
import { Heading, Box } from 'grommet'

const LineHeading = ({lineColor, children, margin, ...rest}) => {
  return (
    <Box
      margin={margin}
      border={{
        color: "brand",
        size: "medium",
        style: "solid",
        side: "bottom"
      }}
      pad={{bottom: "small"}}>
      <Heading
        {...rest}
        margin="none">
        {children}
      </Heading>
    </Box>
  )
}


export default React.memo(LineHeading)