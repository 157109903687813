import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import QueueListItem from './QueueListItem'

const QueueList = ({ queues }) => {
  return (
    <Box
      direction="row"
      animation="fadeIn"
      wrap
      margin={{ vertical: 'large' }}
      justify="center">
      {queues.map(q => <QueueListItem key={`queue_${q.id}`} queue={q}/>)}
    </Box>
  )
}

QueueList.propTypes = {
  queues: PropTypes.array
}

export default React.memo(QueueList)