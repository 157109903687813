import { useMutation, useQuery, useQueryClient } from "react-query";
import api from '../data/network'

export function useFollowers(ticketId) {
  const followers = useQuery(['followers', ticketId], async() => {
    const response = await api.followers.search({ticket: ticketId})
    return response.result
  }, {
    enabled: !!ticketId
  })

  return followers
}

export function useFollowersMutations() {
  const queryClient = useQueryClient()
  // add
   const add = useMutation(({ticketId, partnerId}) => api.followers.create({ticket_id: ticketId, partner_id: partnerId }), {
    onSuccess: () => {
      queryClient.invalidateQueries('followers', { active: true })
    },
  })
  // remove
  const remove = useMutation((followerId) => api.followers.remove(followerId), {
    onSuccess: () => {
      queryClient.invalidateQueries('followers', { active: true })
    },
  })

  return { add, remove }
}