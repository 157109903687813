import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  Box,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from "grommet"
import { getRoute, TICKET_DETAILS } from "../../../navigation/routes"
import LinkAnchor from "../../Common/LinkAnchor"
import {
  Bug,
  Chat,
  Phone,
  CaretDown,
  CaretUp,
  Radial,
  Alert,
} from "grommet-icons"
import ProrityIcon from "../PriorityIcon"
import { useTranslation } from "react-i18next"
import { formatDate } from "../../../utils/date"
import { useQueueTypes } from "../../../hooks/queues"

const icons = {
  bug: <Bug />,
  chat: <Chat />,
  phone: <Phone />,
  hardware: <Phone />,
}

const TableCellHeaders = styled(TableCell)`
  cursor: pointer;
  font-weigth: bol;
`
const TicketRow = styled(TableRow)`
  padding: 20px 0px !important;
`

const TableCaret = ({ visible, direction }) => {
  return visible ? (
    direction === 1 ? (
      <CaretDown size="small" />
    ) : (
      <CaretUp size="small" />
    )
  ) : null
}

const TicketTable = ({ tickets = [] }) => {
  const { t } = useTranslation()
  const [order, setOrder] = React.useState("priority")
  const [desc, setDesc] = React.useState(-1)

  const {types} = useQueueTypes()

  if (order) {
    tickets.sort((a, b) => {
      if (a[order] > b[order]) return 1 * desc
      if (a[order] < b[order]) return -1 * desc
      return 0
    })
  }

  const handleReorder = (field) => {
    if (order === field) {
      setDesc(desc * -1)
    } else {
      setOrder(field)
      setDesc(1)
    }
  }

  return (
    <Box width={{ max: "100%" }} overflow="auto" pad={{top: "medium"}}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCellHeaders
              scope="col"
              onClick={() => handleReorder("pending_approvals")}
            >
              <Box direction="row" align="center" gap="small" justify="between">
                <TableCaret
                  visible={order === "pending_approvals"}
                  direction={desc}
                />
              </Box>
            </TableCellHeaders>
            <TableCellHeaders
              scope="col"
              onClick={() => handleReorder("type_id")}
            >
              <Box direction="row" align="center" gap="small" justify="between">
                {t("Ticket.Type")}
                <TableCaret visible={order === "type_id"} direction={desc} />
              </Box>
            </TableCellHeaders>

            <TableCellHeaders scope="col" onClick={() => handleReorder("code")}>
              <Box direction="row" align="center" gap="small" justify="between">
                {t("Ticket.Reference")}
                <TableCaret visible={order === "code"} direction={desc} />
              </Box>
            </TableCellHeaders>
            <TableCellHeaders
              scope="col"
              onClick={() => handleReorder("name")}
              size="medium"
            >
              <Box direction="row" align="center" gap="small" justify="between">
                {t("Ticket.Title")}
                <TableCaret visible={order === "name"} direction={desc} />
              </Box>
            </TableCellHeaders>
            <TableCellHeaders
              scope="col"
              onClick={() => handleReorder("stage")}
            >
              <Box direction="row" align="center" gap="small" justify="between">
                {t("Ticket.State")}
                <TableCaret visible={order === "stage"} direction={desc} />
              </Box>
            </TableCellHeaders>
            <TableCellHeaders
              scope="col"
              onClick={() => handleReorder("created_by")}
            >
              <Box direction="row" align="center" gap="small" justify="between">
                {t("Ticket.CreatedBy")}
                <TableCaret visible={order === "created_by"} direction={desc} />
              </Box>
            </TableCellHeaders>
            <TableCellHeaders
              scope="col"
              onClick={() => handleReorder("deadline_date")}
            >
              <Box direction="row" align="center" gap="small" justify="between">
                {t("Ticket.Deadline")}
                <TableCaret
                  visible={order === "deadline_date"}
                  direction={desc}
                />
              </Box>
            </TableCellHeaders>
            <TableCellHeaders
              scope="col"
              onClick={() => handleReorder("priority")}
            >
              <Box direction="row" align="center" gap="small" justify="between">
                <Radial />
                <TableCaret visible={order === "priority"} direction={desc} />
              </Box>
            </TableCellHeaders>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tickets.map((ticket) => (
            <TicketRow key={`ticket_${ticket.id}`} pad={{ vertical: "medium" }}>
              <TableCell scope="row" pad="small">
                {ticket.pending_approvals > 0 ? (
                  <LinkAnchor
                    to={getRoute(TICKET_DETAILS, {
                      queueId: ticket.queue_slug,
                      ticketId: ticket.id,
                    })}
                  >
                    <Alert color="status-critical" />
                  </LinkAnchor>
                ) : null}
              </TableCell>
              <TableCell scope="row" pad="small">

                {types[ticket.queue_id] && types[ticket.queue_id].types[ticket.type_id] &&
                  icons[types[ticket.queue_id].types[ticket.type_id].icon]}
              </TableCell>

              <TableCell scope="row" pad="small">
                <LinkAnchor
                  to={getRoute(TICKET_DETAILS, {
                    queueId: ticket.queue_slug,
                    ticketId: ticket.id,
                  })}
                >
                  {ticket.code}
                </LinkAnchor>
              </TableCell>
              <TableCell scope="row" pad="small">
                <LinkAnchor
                  to={getRoute(TICKET_DETAILS, {
                    queueId: ticket.queue_slug,
                    ticketId: ticket.id,
                  })}
                >
                  {ticket.name}
                </LinkAnchor>
              </TableCell>
              <TableCell scope="row" pad="small">
                {ticket.stage}
              </TableCell>
              <TableCell scope="row" pad="small">
                {ticket.creator_name}
              </TableCell>
              <TableCell scope="row" pad="small">
                {ticket.deadline_date ? formatDate(ticket.deadline_date) : ""}
              </TableCell>
              <TableCell scope="row" pad="small">
                <ProrityIcon priority={ticket.priority} />
              </TableCell>
            </TicketRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

TicketTable.propTypes = {
  tickets: PropTypes.array,
}

export default React.memo(TicketTable)
