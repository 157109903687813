import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as routes from '../../navigation/routes';
import { useAuth } from '../../hooks/auth';

const PrivateRoute = (props) => {
    const { user } = useAuth()
    const redirectUrl = window.location.pathname !== '/login' ? `${routes.LOGIN}?next=${window.location.pathname}` : routes.LOGIN
    return user === null ? <Redirect to={redirectUrl} /> : <Route {...props}/>
}

export default React.memo(PrivateRoute);