import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { TICKET_DETAILS } from '../../navigation/routes'

import { Box, Text, Anchor } from 'grommet'
import { LinkPrevious, Download } from 'grommet-icons'
import { useRouter } from '../../hooks/router'

const NavbarBox = styled(Box)`
  position: absolute;
  background: rgba(0,0,0,0.5);
  z-index: 1000;
  width: 100%;
`

const AttachmentsNavbar = ({attachment}) => {
  const params = useParams()
  const router = useRouter()

  const handleBack = () => router.push(TICKET_DETAILS, params)
  return (
    <NavbarBox
      pad="medium"
      align="center"
      justify="between"
      direction="row">
        <LinkPrevious 
          cursor="pointer"
          onClick={handleBack} 
          color="#fff"
          size="medium"/>
        <Box direction="row" gap="small">
          <Text>{attachment.name}</Text>
          <Anchor href={attachment.url}>

            <Download color="#fff"/>
          </Anchor>
        </Box>
    </NavbarBox>
  )
}

AttachmentsNavbar.propTypes = {
  attachment: PropTypes.object
}

export default React.memo(AttachmentsNavbar)