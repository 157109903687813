import _keyBy from "lodash/keyBy"
import React from "react"
import PropTypes from "prop-types"
import { Box, Drop } from "grommet"
import TicketTypeListItem from "./TicketTypeListItem"

const TicketTypeSelect = ({ types, selected, onChange }) => {
  const [open, setOpen] = React.useState(false)
  const [target, setTarget] = React.useState()
  const targetRef = React.useCallback(setTarget, [])

  const currentType = _keyBy(types, "id")[selected]

  const handleClick = type => {
    setOpen(false)
    onChange(type)
  }

  return (
    <Box ref={targetRef}>
      <Box
        onClick={() => setOpen(!open)}
        margin={{ top: "medium" }}
      >
        <TicketTypeListItem type={currentType} />
      </Box>
      {open && target && (
        <Drop
          onClickOutside={() => setOpen(false)}
          onEsc={() => setOpen(false)}
          align={{ top: "bottom", left: "left" }}
          target={target}
        >
          <Box>
            {types.map(type => (
              <TicketTypeListItem
                key={`type_${type.id}`}
                type={type}
                onClick={handleClick}
              />
            ))}
          </Box>
        </Drop>
      )}
    </Box>
  )
}

TicketTypeSelect.propTypes = {
  selected: PropTypes.number,
  types: PropTypes.array,
  onChange: PropTypes.func
}

export default React.memo(TicketTypeSelect)
