import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const EditorField = ({ label, value, margin, editorRef, error, onChange }) => {
  const content = htmlToDraft(value)
  const contentState = ContentState.createFromBlockArray(content.contentBlocks)
  const initialState = EditorState.createWithContent(contentState)
  const [editorState, setEditorState] = React.useState(initialState)

  const handleEditorChange = (value) => {
    setEditorState(value)
    if(value.getCurrentContent().hasText()){
      onChange(draftToHtml(convertToRaw(value.getCurrentContent())))
    } else {
      onChange('')
    }
  }

  return (
    <Box margin={margin}>
       {label && (
        <Text margin={{ bottom: "small" }}>{label}</Text>
      )}
      <Box 
        editorRef={editorRef}
        border="small">
        <Editor
          editorRef={editorRef}
          editorState={editorState}
          editorStyle={{paddingLeft: 16, paddingRight: 16, minHeight: 300}}
          onEditorStateChange={handleEditorChange}
          toolbar={{
            options: ['inline', 'list', 'link', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },

          }}
        />
      </Box>
      {error && (
        <Text 
          size="small"
          color="status-error"
          margin={{ top: "small" }}>{error}</Text>
      )}
    </Box>
  )
}

EditorField.defaultProps = {
  margin: "none",
  editorRef: () => {}
}

EditorField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default React.memo(EditorField)
