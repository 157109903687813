import React from "react"
import { Grommet } from "grommet"
import { theme } from "../../Theme"
import { useAuth } from "../../hooks/auth"

const AppContainer = ({ children }) => {
  const { initialized } = useAuth()

  return <Grommet theme={theme}>{initialized ? children : null}</Grommet>
}
export default React.memo(AppContainer)
