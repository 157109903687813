import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Heading, Paragraph, ResponsiveContext } from "grommet";
import {
  Bug,
  Chat,
  Phone,
  CircleQuestion,
  CircleInformation,
  Troubleshoot
} from "grommet-icons";

const icons = {
  bug: <Bug />,
  chat: <Chat />,
  phone: <Phone />,
  question: <CircleQuestion />,
  information: <CircleInformation />,
  hardware: <Troubleshoot />
};

const ItemBox = styled(Box)`
  cursor: pointer
  transition: 0.5s background;

  &:hover {
    background-color: #ededed
  }
`;

const TicketTypeListItem = ({ type, onClick }) => {
  return (
    <ItemBox pad="medium" direction="row" onClick={() => onClick(type)}>
      <ResponsiveContext.Consumer>
        {size =>
          size !== "small" && (
            <Box pad="small" justify="center">
              {type.icon ? icons[type.icon] : null}
            </Box>
          )
        }
      </ResponsiveContext.Consumer>
      <Box margin={{ left: "small" }}>
        <Heading level="5" margin="none">
          {type.name}
        </Heading>
        <Paragraph fill margin="none">
          {type.description}
        </Paragraph>
      </Box>
    </ItemBox>
  );
};

TicketTypeListItem.defaultProps = {
  onClick: () => {}
};

TicketTypeListItem.propTypes = {
  type: PropTypes.object,
  onClick: PropTypes.func
};

export default React.memo(TicketTypeListItem);
