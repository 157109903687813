import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import _isEqual from "lodash/isEqual"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useCurrentQueue } from "./queues"
import { useQueryParams, ArrayParam, StringParam } from "use-query-params"
import { readFile } from "./attachments"
import api from "../data/network"


export function useTickets() {
  const queue = useCurrentQueue()
  const { filters } = useTicketFilters()
  const results = useQuery(
    ["tickets", { ...filters, queue: queue?.slug }],
    async () => {
      const response = await api.tickets.search({
        ...filters,
        queue: queue?.slug,
      })
      return response.result || []
    },
    {
      enabled: !!filters && !!queue,
    }
  )

  return results
}

export function useTicket(ticketId) {
  const results = useQuery(
    ["tickets", ticketId],
    async () => {
      const response = await api.tickets.get(ticketId)
      return response
    },
    {
      enabled: !!ticketId,
    }
  )

  return results
}

const TicketFiltersContext = createContext()

export function TicketFiltersProvider({ children }) {
  const [queueFilters, setQueueFilters] = useState({})

  const updateQueueFilters = useCallback((queueSlug, filters) => {
    const newFilters = {
      ...queueFilters,
      [queueSlug]: {queue: queueSlug, ...filters}
    }
    setQueueFilters(newFilters)
  }, [queueFilters, setQueueFilters])

  const contextValue = useMemo(() => ({ queueFilters, updateQueueFilters }), [queueFilters, updateQueueFilters])

  return (
    <TicketFiltersContext.Provider value={contextValue}>{children}</TicketFiltersContext.Provider>
  )
}

export function useTicketFilters() {
  const {queueFilters, updateQueueFilters } = useContext(TicketFiltersContext)

  const queue = useCurrentQueue()
  // const queueFilters = useSelector(selectQueueFilters)

  const [params] = useQueryParams({
    query: StringParam,
    states: StringParam,
    state: ArrayParam,
    created_by: ArrayParam,
  })

  useEffect(() => {
    if (queue) {
      if (!queueFilters[queue.slug]) {
        updateQueueFilters(queue.slug, params)
      }
    }
  }, [params, queue, updateQueueFilters,  queueFilters])

  const clearFilters = useCallback(() => {
    if (queue) {
      updateQueueFilters(queue.slug, {})
    }
  }, [updateQueueFilters, queue])

  const setFilters = useCallback(
    (data) => {
      if (queue) {
        if (!_isEqual(data, queueFilters[queue.slug])) {
          updateQueueFilters(queue.slug, data)
        }
      }
    },
    [queue, updateQueueFilters, queueFilters]
  )

  return {
    filters: queue ? queueFilters[queue.slug] : null,
    setFilters,
    clearFilters,
  }
}

export function useTicketMutations() {
  const queryClient = useQueryClient()

  // add
  const add = useMutation(
    async (payload) => {
      return new Promise(async (resolve, reject) => {
        const { files, ...data } = payload
        try {
          const ticket = await api.tickets.create(data)

          for (let file of files) {
            const fileData = {
              name: file.name,
              ticket_id: ticket.id,
              mimetype: file.type,
            }
            fileData["content"] = await readFile(file)
            await api.attachments.create(fileData)
          }

          resolve(ticket)
        } catch (e) {
          reject(e)
        }
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tickets", { active: true })
      },
    }
  )
  // update
  const update = useMutation(
    ({ ticketId, data }) => api.tickets.update(ticketId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tickets", { active: true })
      },
    }
  )
  // add follower
  const addFollower = useMutation((data) => api.followers.create(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("tickets", { active: true })
    },
  })

  // remove follower
  const removeFollower = useMutation((data) => api.followers.remove(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("tickets", { active: true })
    },
  })

  return {
    add,
    update,
    addFollower,
    removeFollower,
  }
}
