import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import TicketTypeListItem from './TicketTypeListItem'

const TicketTypeList = ({ types, onClick }) => {
  return (
    <Box
      gap="medium">
        {types.map(type => 
          <TicketTypeListItem 
            key={`type_${type.id}`} 
            type={type}
            onClick={onClick}/>)}
    </Box>
  )
}

TicketTypeList.propTypes = {
  types: PropTypes.array,
  onClick: PropTypes.func
}

export default React.memo(TicketTypeList)