import React from 'react'
import { Button } from 'grommet'
import { useRouter } from '../../hooks/router'

const LinkButton = ({ to, children, ...rest}) => {
  const router = useRouter()
  const handleClick = () => router.push(to)

  return (
    <Button 
      {...rest} 
      onClick={handleClick}/>
  )
}

export default LinkButton