import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Image, Box } from 'grommet'
import { DocumentCsv, DocumentExcel, DocumentPdf, Document } from 'grommet-icons'

const PreviewImage = styled(Image)`
  max-width: 100%;
  max-height: 100%;
`

const AttachmentPreview = ({attachment}) => {
  const renderPreview = () => {
    if (attachment.mimetype.indexOf('image') >= 0) {
      return <PreviewImage 
        src={attachment.url} />
    } else if (attachment.mimetype.indexOf('pdf') >= 0) {
      return <DocumentPdf size="xlarge" />
    } else if (attachment.mimetype.indexOf('excel') >= 0) {
      return <DocumentExcel size="xlarge" />
    } else if (attachment.mimetype.indexOf('csv') >= 0) {
      return <DocumentCsv size="xlarge" />
    } else {
      return <Document size="xlarge" />
    }
  }

  return (
    <Box
      align="center"
      justify="center"
      height="full"
      width="full">
        {renderPreview()}
    </Box>
  )
}

AttachmentPreview.propTypes = {
  attachment: PropTypes.object
}

export default React.memo(AttachmentPreview)