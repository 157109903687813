export const theme = {
  global: {
    font: {
      family: 'Nunito',
    },
    colors: {
      brand: '#267dff',
      focus: '#48dbfb'
    },
  },
  button: {
    border: {
      radius: `10px`,
    },
    padding: {
      vertical: '7px'
    }
  }
};
