import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import { Box } from 'grommet'
import { TICKET_ATTACHMENTS } from '../../navigation/routes'
import AttachmentPreview from './AttachmentThumbnail'
import { useRouter } from '../../hooks/router'

const AttachmentList = ({ attachments, size = "small", basis }) => {
  const router= useRouter()
  const { queueId, ticketId } = useParams()
  const handleClick = (attachment) => router.push(TICKET_ATTACHMENTS, { queueId, ticketId, attachmentId: attachment.id})

  return (
    attachments ? (
      <Box>
        <Box direction="row" wrap>
          {attachments.map(attachment => (
            <AttachmentPreview 
              size={size} 
              basis={basis} 
              attachment={attachment} 
              onClick={handleClick}
              key={`attach_${attachment.id}`} />
          ))}
        </Box>
      </Box>
    ) : null
  )
}

AttachmentList.propTypes = {
  attachments: PropTypes.array,
  onClick: PropTypes.func,
  size: PropTypes.string,
  basis: PropTypes.string,
}

export default React.memo(AttachmentList)