import React from 'react'
import { useTranslation } from "react-i18next"

import AppWrapper from '../../components/App/AppWrapper'
import QueueList from '../../components/Queues/QueueList'
import PageTitle from '../../components/Common/PageTitle'
import Spinner from '../../components/Common/Spinner'
import { useQueues } from '../../hooks/queues'



const DashboardPage = () => {
  const { t } = useTranslation()
  const results = useQueues()
  const {data, isLoading} = results
  
  //Centro di assistenza
  //Benvenuto nel centro di assistenza Metadonors. Seleziona il prodotto su cui necessiti assistenza.
  return (
    <AppWrapper>
      <PageTitle title={t("Queue.Helpdesk")}>
        {t("Queue.WelcomeMessage")}
      </PageTitle>
      {isLoading || !data? <Spinner/> : <QueueList queues={data}/>}
      
    </AppWrapper>

  )
}

export default DashboardPage