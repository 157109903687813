import React from "react"
import { Box, Heading, Text } from "grommet"
import { formatDatetime } from "../../utils/date"
import { useTranslation } from "react-i18next"
import PageTitle from "../Common/PageTitle"
import parse from "html-react-parser"

import TicketPriorityForm from "./Details/TicketPriorityForm"
import { useTicketMutations } from "../../hooks/tickets"

const TicketContent = ({ ticket }) => {
  const { t } = useTranslation()
  const {update} = useTicketMutations()

  const handlePriorityChange = priority => {
    update.mutate({ ticketId: ticket.id, data: { priority: priority } })
  }
  return (
    <Box>
      <Box direction="row" align="center" margin={{ top: "medium" }}>
        <TicketPriorityForm ticket={ticket} onChange={handlePriorityChange} />
        <Heading level="5" margin={{ left: "xsmall", vertical: "small" }}>
          {ticket.code}
        </Heading>
      </Box>

      <PageTitle title={ticket.name} margin="none" />
      <Text size="small" color="muted" margin={{ vertical: "small" }}>
        {t("CreatedBy")} <b>{ticket.creator_name}</b> il{" "}
        <b>{formatDatetime(ticket.create_date)}</b>
      </Text>
      <Box
        round="small"
        pad={{ horizontal: "small" }}
        margin={{ vertical: "small" }}
        background="#f7f7f7"
      >
        {ticket.description && parse(ticket.description)}
      </Box>
    </Box>
  )
}

export default React.memo(TicketContent)
