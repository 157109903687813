
import _keyBy from 'lodash/keyBy'
import { useParams } from "react-router"
import { useQuery } from "react-query"
import api from '../data/network'
import { useEffect, useState } from 'react'

export function useCurrentQueue() {
  const { queueId } = useParams()
  const queuesByIds = useQueuesBySlug()
  return queuesByIds[queueId]
}

export function useQueues() {
  const results = useQuery(['queues'], async() => {
    const response = await api.queues.search()
    return response ? response.result : []
  })
  
  return results
}

export function useQueuesById() {
  const queues = useQueues()
  return _keyBy(queues.data, 'id')
}

export function useQueuesBySlug() {
  const queues = useQueues()
  return _keyBy(queues.data, 'slug')
}

export function useQueueTypes() {
  const [types, setTypes] = useState({})
  const queues = useQueues()

  useEffect(() => {
    const types = (queues.data || []).map(q => ({
      queueId: q.id,
      types: _keyBy(q.type_ids, 'id')
    }))
    setTypes(_keyBy(types, 'queueId'))
  }, [queues.data, setTypes])

  return {
    types
  }
}
