import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Text } from 'grommet'

const TitleBox = styled(Box)`
  position: relative;
`

const TitleLine = styled(Box)`
  position: absolute;
  height: 2px;
  width: 100%;
  z-index: 1;
  background-color: #ededed;
`

const TitleText = styled(Text)`
  background-color: #fff;
  z-index: 2;
`

const LinedTitle = ({title, size = "medium", margin={vertical: "medium"}}) => {

  return (
    <TitleBox
      justify="center"
      align="center"
      margin={margin}>
      <TitleText pad="medium"
        size={size}
        weight="bold"
        >{title}</TitleText>
        <TitleLine/>
    </TitleBox>
  )
}

LinedTitle.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string
}

export default React.memo(LinedTitle)