import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Text, Button } from 'grommet'
import { DocumentCsv, Close, DocumentExcel, DocumentPdf, Document } from 'grommet-icons'

const PreviewImage = styled.img`
  width: 100%;
  height: auto;
`

const PreviewBox = styled(Box)`
  border: 1px solid #cdcdcd
`

const AttachmentThumbnailBox = styled(Box)`
  position: relative;
  cursor: pointer;

`

const RemoveBox = styled(Box)`
  position: absolute;
  right: 8px;
  top: 8px;
`

const AttachmentThumbnail = ({ attachment, size, basis, onDelete = () => {}, onClick = () => {} }) => {


  const renderPreview = () => {
    if (attachment.mimetype.indexOf('image') >= 0) {
      return <PreviewImage src={attachment.url} />
    } else if (attachment.mimetype.indexOf('pdf') >= 0) {
      return <DocumentPdf size="large" />
    } else if (attachment.mimetype.indexOf('excel') >= 0) {
      return <DocumentExcel size="large" />
    } else if (attachment.mimetype.indexOf('csv') >= 0) {
      return <DocumentCsv size="large" />
    } else {
      return <Document size="large" />
    }
  }

  return (
    <AttachmentThumbnailBox
      onClick={() => onClick(attachment)}
      basis={basis}
      margin={{ right: "small", bottom: "small" }}>
      {onDelete ? (
        <RemoveBox>
          <Button plain icon={<Close />} onClick={() => onDelete(attachment)} />
        </RemoveBox>
      ) : null}

      <PreviewBox
        overflow="hidden"
        justify="center"
        align="center"
        height={size}
        width={size}>
        {renderPreview()}
      </PreviewBox>
      {size !== "xsmall" ? (
        <Text
          margin={{ vertical: "small" }}
          truncate
          size="small">{attachment.name}</Text>
      ) : null}
    </AttachmentThumbnailBox>
  )
}

AttachmentThumbnail.propTypes = {
  file: PropTypes.object,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  size: PropTypes.string
}

export default React.memo(AttachmentThumbnail)