import React from "react";
import PropTypes from "prop-types";
import { Box, Text } from "grommet";
import { formatDate, formatDatetime } from "../../utils/date";
import TaskApprovalForm from "./TaskApprovalForm";
import { useTranslation } from "react-i18next"

const TaskListItem = ({ task }) => {
  const { t } = useTranslation()
  return (
    <Box
      pad={{ vertical: "small", horizontal: "medium" }}
      margin={{ bottom: "small" }}
      elevation="small"
    >
      <Box direction="row" justify="between">
        <Box>
          <Text size="small" weight="bold">
            {task.code}
          </Text>
          <Text> {task.name} </Text>
          <Text> {task.stage} </Text>
        </Box>

        <Box direction="row" gap="large">
          {task.deadline_date && (
            <Box>
              <Text size="small" weight="bold">
                {t('Task.PlannedFor')}
              </Text>
              <Text> {formatDate(task.deadline_date)} </Text>
            </Box>
          )}

          {task.planned_hours > 0 && (
            <Box>
              <Text size="small" weight="bold">
                {t('Task.Extimated')}
              </Text>
              <Text> {task.planned_hours} ore </Text>
            </Box>
          )}
        </Box>
      </Box>
      {task.require_customer_approval && !task.customer_approval && (
        <TaskApprovalForm task={task} />
      )}

      <Box alignSelf="end">
        {task.customer_approval === "approved" && (
          <Text color="neutral-1" size="small">
            {t('Task.ApprovedBy')} {task.customer_approval_partner_name} {t('Task.On')}
            {formatDatetime(task.customer_approval_date)}
          </Text>
        )}

        {task.customer_approval === "rejected" && (
          <Text color="status-critical" size="small">
            {t('Task.RejectedBy')} {task.customer_approval_partner_name} {t('Task.On')}
            {formatDatetime(task.customer_approval_date)}
          </Text>
        )}
      </Box>
    </Box>
  );
};

TaskListItem.propTypes = {
  task: PropTypes.object
};

export default React.memo(TaskListItem);
