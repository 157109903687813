import { useMutation, useQuery, useQueryClient } from "react-query"
import api from "../data/network"

export function useAttachments(ticketId) {
  const attachments = useQuery(
    ["attachments", ticketId],
    async () => {
      const response = await api.attachments.search({ ticket: ticketId })
      return response.result
    },
    {
      enabled: !!ticketId,
    }
  )

  return attachments
}

export function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const b64 = reader.result.replace(/^data:.+;base64,/, '');
      resolve(b64)
    }

    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export function useAttachmentsMutations() {
  const queryClient = useQueryClient()
  // add
  const add = useMutation(
    async({file, ticketId}) => {
      const data = { name: file.name, ticket_id: ticketId, mimetype: file.type }
      data['content'] = await readFile(file)
      return api.attachments.create(data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("messages", { active: true })
      },
    }
  )
  return { add }
}
