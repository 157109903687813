import { useQueryClient } from "react-query"
import { useQueryParam, StringParam } from 'use-query-params';
import { useRouter } from "../../hooks/router"

const TOKEN_KEY = 'auth_token'


const OauthPage = () => {
  const queryClient = useQueryClient()
  const [code] = useQueryParam('code', StringParam);
  const { push } = useRouter()


  const data = {
    code: code,
    client_id: 'UDkjbDXr7FNUgudU6uud0TSIScKsVaSxjxOCgRHG',
    redirect_uri: 'https://helpdesk.metadonors.it/oauth',
    grant_type: 'authorization_code'

  }

  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  fetch('https://accounts.metadonors.it/oauth/token/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: formBody
  })
    .then(async(response) => {
      const data = await response.json()
      localStorage.setItem(TOKEN_KEY, data.access_token)
      queryClient.invalidateQueries(["user"])
      window.location.href = "/"
    })
    .catch(error => {
      push("/login?error=true")
    })

  return null
}

export default OauthPage