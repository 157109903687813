import React from "react";
import PropTypes from "prop-types";
import { Box } from "grommet";
import TaskListItem from "./TaskListItem";

const TaskList = ({ tasks }) => {
  return tasks ? (
    <Box>
      {tasks.map(task => (
        <TaskListItem key={`task_${task.id}`} task={task} />
      ))}
    </Box>
  ) : null;
};

TaskList.propTypes = {
  tasks: PropTypes.array
};

export default React.memo(TaskList);
