import React from "react"
import styled from "styled-components"
import { Box, Button } from "grommet"
import { useTranslation } from "react-i18next"

const FormButton = styled(Button)`
  text-transform: uppercase;
`

const TaskApprovalForm = ({ task }) => {
  const { t } = useTranslation()

  const handleSubmit = value => {
    // TODO
  }

  return (
    <Box justify="end" gap="medium" direction="row">
      <FormButton color="brand" plain onClick={() => handleSubmit("rejected")}>
        {t("Action.Reject")}
      </FormButton>
      <FormButton color="brand" plain onClick={() => handleSubmit("approved")}>
        {t("Action.Approve")}
      </FormButton>
    </Box>
  )
}

export default React.memo(TaskApprovalForm)
