import React, { useState, useEffect } from "react"
import CheckboxSelectField from "../../Forms/CheckboxSelectField"
import { useTranslation } from "react-i18next"

function StageSelector({ queue, onChange, value }) {
  const { t } = useTranslation()
  const [stages, setStages] = useState(null)

  useEffect(() => {
    if (queue) {
      setStages(
        queue.stage_ids.map((s) => ({
          value: `${s.id}`,
          label: s.name,
        }))
      )
    }
  }, [queue])

  return (
    stages && (
      <CheckboxSelectField
        style={{
          border: undefined
        }}
        name="created_by"
        label={t("Ticket.State")}
        options={stages}
        onChange={onChange}
        value={value}
      />
    )
  )
}

export default StageSelector
