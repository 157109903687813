import _keyBy from 'lodash/keyBy'
import React from 'react'
import PropTypes from "prop-types"

import { Box, Text, Select } from 'grommet'

const SelectField = ({ options, name, label, empty_label, margin, value, error, onChange, empty = true, ...rest }) => {

  if(empty) {
    options = [
      { value: '-', label: empty_label},
      ...options
    ]
  }

  const optionsByValue = _keyBy(options, 'value')

  return (
    <Box 
      margin={margin}>
       {label && (
        <Text margin={{ bottom: "small" }}>{label}</Text>
      )}
      <Select
        name={name}
        options={options}
        valueKey={(o)=> o.value}
        labelKey={(o)=> o.label}
        value={optionsByValue[value] ? optionsByValue[value] : ''}
        onChange={({option}) => {
          onChange(name)(option.value)
        }}
        {...rest}
      />
      {error && (
        <Text 
          size="small"
          color="status-error"
          margin={{ top: "small" }}>{error}</Text>
      )}
    </Box>
  )
}

SelectField.defaultProps = {
  margin: {vertical: "medium"}
}

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default SelectField


