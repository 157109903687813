import { useQuery } from "react-query";
import api from '../data/network'

export function useTasks(ticketId) {
  const tasks = useQuery(['tasks', ticketId], async() => {
    const response = await api.tasks.search({ticket: ticketId})
    return response.result
  }, {
    enabled: !!ticketId
  })

  return tasks
}
