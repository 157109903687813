import axios from 'axios'
import { HOST } from './config'

export const TOKEN_KEY = 'auth_token'

const API_ENDPOINT = `${HOST}/api`
const HELPDESK_ENDPOINT = `${API_ENDPOINT}/helpdesk/v1`

const Resource = (client, endpoint) => {
  const get = (id) => client.get(`${endpoint}/${id}/`)
  const search = (params) => client.get(endpoint, params)
  const create = (data) => client.post(endpoint, data)
  const update = (id, data) => client.put(`${endpoint}/${id}/`, data)
  const remove = (id) => client.remove(`${endpoint}/${id}/`)

  return { get, search, create, update, remove }
}


const BackendClient = (http) => {

  const headers = () => {
    const headers = {}
    const token = localStorage.getItem(TOKEN_KEY)
    headers['Content-type'] = 'application/json'
    if (token) {
      headers['Authorization'] = 'Token ' + token
    }
    return headers
  }
  
  const userInfo = () => {
    return http.get(`${API_ENDPOINT}/users/me`, {headers: headers()})
      .then(response => response.data)
  }

  const login = (login, password) => {
    return http.post(`${API_ENDPOINT}/auth/token`, { login, password }, {headers: headers()})
      .then(response => {
        localStorage.setItem(TOKEN_KEY, response.data.auth_token)
        return userInfo()
      })
  }

  const logout = () => {
    localStorage.removeItem(TOKEN_KEY)
  }

  const get = (path, params={}) => {
    return new Promise((resolve, reject) => {
      http({
        url: HELPDESK_ENDPOINT + path,
        method: 'get',
        params: params,
        headers: headers()
      }).then(response => {
        if (response.data.error) return reject('Not Authenticated')
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  const post = (path, data) => {
    return new Promise((resolve, reject) => {
      http({
        url: HELPDESK_ENDPOINT + path,
        method: 'post',
        headers: headers(),
        data: data
      }).then(response => {
        if (response.data.error) return reject('Not Authenticated')
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  const put = (path, data) => {
    return new Promise((resolve, reject) => {
      http({
        url: HELPDESK_ENDPOINT + path,
        method: 'put',
        headers: headers(),
        data: data
      }).then(response => {
        if (response.data.error) return reject('Not Authenticated')
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  const remove = (path) => {
    return new Promise((resolve, reject) => {
      http({
        url: HELPDESK_ENDPOINT + path,
        method: 'delete',
        headers: headers()
      }).then(response => {
        if (response.data.error) return reject('Not Authenticated')
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  return {
    userInfo,
    login,
    logout,
    get,
    post,
    put,
    remove,
  }
}

const Backend = (http) => {
  const client = BackendClient(http)

  const login = client.login
  const logout = client.logout
  const userInfo = client.userInfo

  const users = Resource(client, '/users')
  const queues = Resource(client, '/queues')
  const tickets = Resource(client, '/tickets')
  const messages = Resource(client, '/messages')
  const attachments = Resource(client, '/attachments')
  const tasks = Resource(client, '/tasks')
  const followers = Resource(client, '/followers')
  const approvals = Resource(client, '/approvals')

  const getToken = () => {
    return localStorage.getItem(TOKEN_KEY)
  }

  return {
    login,
    logout,
    userInfo,
    users,
    queues,
    tickets,
    messages,
    attachments,
    tasks,
    followers,
    approvals, 
    getToken
  }
}

const api = Backend(axios)

export default api