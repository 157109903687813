import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Formik } from "formik"
import { Box, Form, Button, Text, Anchor } from "grommet"
import EditorField from "../Forms/EditorField"
import AttachmentsField from "../Forms/AttachmentsField"
import Avatar from "react-avatar"
import { useTranslation } from "react-i18next"
import { useAuth } from "../../hooks/auth"

const initialValues = {
  body: "",
  files: [],
}

const InputTriggerBox = styled(Box)`
  background-color: #f7f7f7;
  border: 1px solid #dfe1e6;
  color: #a1a1a1;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ededed;
  }
`

const validate = (values) => {
  const errors = {}

  if (!values.body) {
    errors["body"] = "E' obbligatorio scrivere qualcosa nel messaggio"
  }

  return errors
}

const MessageForm = ({ ticketId, onSubmit }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const { user } = useAuth()

  const handleSubmit = (values, { resetForm }) => {
    values["ticket_id"] = parseInt(ticketId)
    onSubmit(values)
    setOpen(false)
    resetForm()
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      render={({ values, errors, touched, handleChange, handleSubmit }) => (
        <Box direction="row" gap="small" align={open ? "start" : "center"}>
          <Avatar
            size="40px"
            email={user.email}
            src={user.image}
            name={user.name}
          />
          {open ? (
            <Box fill>
              <Form onSubmit={handleSubmit}>
                <EditorField
                  margin="none"
                  name="body"
                  value={values.body}
                  error={touched.body && errors.body}
                  onChange={handleChange("body")}
                />

                <AttachmentsField
                  name="files"
                  label="Attachments"
                  onChange={handleChange("files")}
                  value={values.files}
                />

                <Box direction="row" gap="small" align="center">
                  <Button type="submit" primary label="Invia" />
                  <Anchor onClick={() => setOpen(false)}>
                    {t("Action.Cancel")}
                  </Anchor>
                </Box>
              </Form>
            </Box>
          ) : (
            <InputTriggerBox
              fill
              pad="small"
              onClick={() => {
                setOpen(true)
              }}
              justify="center"
            >
              <Text>{t("Message.ClickHereToAddAComment")}</Text>
            </InputTriggerBox>
          )}
        </Box>
      )}
    />
  )
}

MessageForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default React.memo(MessageForm)
