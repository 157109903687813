import React from "react"
import { Route, Switch } from "react-router-dom"
import PrivateRoute from "../components/App/PrivateRoute"
import ScrollToTop from "../components/App/ScrollToTop"
import { QueryParamProvider } from "use-query-params"

import LoginPage from "../screens/Users/LoginPage"
import OauthPage from "../screens/Users/OauthPage"
import DashboardPage from "../screens/Queues/DashboardPage"
import QueuePage from "../screens/Queues/QueuePage"
import TicketsListPage from "../screens/Tickets/TicketsListPage"
import NotFoundPage from "../screens/NotFoundPage"
import CreateTicketPage from "../screens/Tickets/CreateTicketPage"
import TicketPage from "../screens/Tickets/TicketPage"
import AttachmentsPage from "../screens/Tickets/AttachmentsPage"

import * as routes from "../navigation/routes"

const AppRouter = () => {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <ScrollToTop>
        <Switch>
          <Route path={routes.LOGIN} component={LoginPage} />
          <Route path={routes.OAUTH} component={OauthPage} />

          <PrivateRoute exact path={routes.HOME} component={DashboardPage} />
          <PrivateRoute
            exact
            path={routes.TICKET_DETAILS}
            component={TicketPage}
          />
          <PrivateRoute
            exact
            path={routes.TICKET_CREATE}
            component={CreateTicketPage}
          />
          {/* <PrivateRoute exact path={routes.ALL_TICKETS} component={TicketsListPage} /> */}
          <PrivateRoute
            exact
            path={routes.QUEUE_TICKETS}
            component={TicketsListPage}
          />
          <PrivateRoute
            exact
            path={routes.QUEUE_DETAIL}
            component={QueuePage}
          />
          <PrivateRoute
            exact
            path={routes.TICKET_ATTACHMENTS}
            component={AttachmentsPage}
          />

          <Route component={NotFoundPage} />
        </Switch>
      </ScrollToTop>
    </QueryParamProvider>
  )
}

export default React.memo(AppRouter)
