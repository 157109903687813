import React from "react"
import { useParams } from "react-router"

import AppWrapper from "../../components/App/AppWrapper"
import PageTitle from "../../components/Common/PageTitle"
import TicketForm from "../../components/Tickets/TicketForm"
import Breadcrums from "../../components/App/Breadcrums"
import TicketTypeSelect from "../../components/Tickets/TicketTypeSelect"
import { useTranslation } from "react-i18next"
import { useQueuesBySlug } from "../../hooks/queues"
import { useTicketMutations } from "../../hooks/tickets"
import { TICKET_DETAILS } from "../../navigation/routes"
import { Box } from "grommet"
import { useRouter } from "../../hooks/router"

const CreateTicketPage = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { queueId, typeId } = useParams()
  const [currentTypeId, setCurrentTypeId] = React.useState(null)
  const queue = useQueuesBySlug()[queueId]
  const ticketMutations = useTicketMutations()

  const handleSubmit = async (payload) => {
    // const { files, ...data } = payload

    ticketMutations.add.mutate(payload, {
      onSuccess: (ticket) => {
        router.push(TICKET_DETAILS, {
          ticketId: ticket.id,
          queueId: ticket.queue_slug,
        })
      },
    })
  }

  const handleTypeChange = (type) => setCurrentTypeId(type.id)

  return (
    <AppWrapper>
      {queue ? (
        <React.Fragment>
          <Breadcrums queueId={queueId} />

          <PageTitle title={t("Ticket.CreateTicket")} />

          <TicketTypeSelect
            selected={currentTypeId || typeId}
            types={queue.type_ids}
            onChange={handleTypeChange}
          />

          <TicketForm
            submitting={ticketMutations.add.isLoading}
            initialValues={{
              queue_id: queue.id,
              type_id: currentTypeId || typeId,
            }}
            onSubmit={handleSubmit}
          />

          {ticketMutations.isError && (
            <Box>
              Error creating ticket
            </Box>
          )}
        </React.Fragment>
      ) : null}
    </AppWrapper>
  )
}

export default React.memo(CreateTicketPage)
