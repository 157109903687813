import React from 'react'
import { TICKET_CREATE } from '../../navigation/routes'

import AppWrapper from '../../components/App/AppWrapper'
import PageTitle from '../../components/Common/PageTitle'
import TicketTypeList from '../../components/Tickets/TicketTypeList'
import Breadcrums from '../../components/App/Breadcrums'
import Spinner from '../../components/Common/Spinner'
import NotFoundPage from '../NotFoundPage'
import { useCurrentQueue, useQueues } from '../../hooks/queues'
import { useRouter } from '../../hooks/router'

const QueuePage = () => {
  const queues = useQueues()
  const queue = useCurrentQueue()
  const router = useRouter()

  const goToTicketForm = (type) => router.push(TICKET_CREATE, {
    queueId: queue.slug,
    typeId: type.id
  })
  

  return !queue && !queues.isLoading? <NotFoundPage/> : (
    <AppWrapper>
      {queues.isFetched ? (
        <React.Fragment>
          <Breadcrums/>
          <PageTitle title={queue.name}>
            {queue.description}
          </PageTitle>

          <TicketTypeList 
            onClick={goToTicketForm}
            types={queue.type_ids}/>
        </React.Fragment>
      ): <Spinner/> }
    </AppWrapper>
  )
}

export default React.memo(QueuePage)