import React from "react"
import { Anchor } from "grommet"
import { useRouter } from "../../hooks/router"

const LinkAnchor = ({ to, ...rest }) => {
  const router = useRouter()

  return (
    <Anchor onClick={() => router.push(to)} {...rest}>
      {rest.children}
    </Anchor>
  )
}

export default React.memo(LinkAnchor)
