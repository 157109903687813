import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Text } from 'grommet'
import { formatDate } from '../../../utils/date'

const TimesOverview = ({ ticket }) => {
  return (
    <Box>
      {ticket.deadline_date && (
        <>
          <Heading level="5" margin={{ bottom: "none" }}>Previsto per</Heading>
          <Text>{formatDate(ticket.deadline_date)}</Text>
        </>
      )}

      {ticket.planned_hours > 0 && (
        <>
          <Heading level="5" margin={{ bottom: "none" }}>Tempo Previsto</Heading>
          <Text>{ticket.planned_hours} ore</Text>
        </>
      )}

      {ticket.worked_hours > 0 && (
        <>
          <Heading level="5" margin={{ bottom: "none" }}>Tempo Lavorato</Heading>
          <Text>{ticket.worked_hours} ore</Text>
        </>
      )}
    </Box>
  )
}

TimesOverview.propTypes = {
  ticket: PropTypes.object
}

export default React.memo(TimesOverview)