  import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import { Form, Button } from "grommet"
import InputField from "../Forms/InputField"
import SelectField from "../Forms/SelectField"
import AttachmentsField from "../Forms/AttachmentsField"
import EditorField from "../Forms/EditorField"
import { useTranslation } from "react-i18next"

const emptyValues = {
  name: "",
  description: "",
  priority: "",
  files: []
}


const TicketForm = ({ initialValues = {}, onSubmit, submitting }) => {
  const { t } = useTranslation()
  const priority = [
    { value: "1", label: t("Media") },
    { value: "2", label: t("Alta") },
    { value: "3", label: t("Bloccante") }
  ]
  const initial = {
    ...emptyValues,
    ...initialValues
  }

  const handleSubmit = values => {
    const data = {
      ...values,
      priority:
        values.priority !== "" && values.priority !== "-"
          ? values.priority
          : "0",
      type_id: parseInt(values.type_id)
    }

    onSubmit(data)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initial}
      validate={validate}
      onSubmit={handleSubmit}
      render={({
        values,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <InputField
            name="name"
            label={t("Ticket.Title")}
            placeholder={t("Ticket.GiveADescriptiveNameToTicket")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />

          <SelectField
            name="priority"
            empty_label={t("Bassa")}
            label={t("Ticket.Priority")}
            options={priority}
            placeholder={t("Ticket.ChooseThePriority")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.priority}
          />

          <EditorField
            label="Descrizione"
            value={values.description}
            onChange={handleChange("description")}
          />

          <AttachmentsField
            name="files"
            label={t("Ticket.Attachments")}
            onChange={handleChange("files")}
            value={values.files}
          />

          <Button
            disabled={submitting}
            type="submit"
            primary
            label={t("Ticket.Send")}
          />
        </Form>
      )}
    />
  )
}

const validate = values => {
  const errors = {}
  return errors
}

TicketForm.propTypes = {
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}

export default React.memo(TicketForm)
