import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import MessageListItem from './MessageListItem'
import MessagesExplander from './MessagesExplander'

const MessageList = ({ messages }) => {
  const [expanded, setExpanded] = React.useState(false)
  const [compressedMessages, setCompressedMessages] = React.useState([])
  const [visibleMessages, setVisibleMessages] = React.useState([])

  React.useEffect(() => {
    if(messages.length < 5) {
      setExpanded(true)
      setVisibleMessages(messages)
    } else {
      setCompressedMessages(messages.slice(0, messages.length - 4))
      setVisibleMessages(messages.slice(messages.length - 4, messages.length))
    }
  }, [messages])


  return (
    <Box> 
      {expanded ? 
        compressedMessages.map(message => <MessageListItem message={message} key={`message_${message.id}`}/>)
        : (
          <MessagesExplander length={compressedMessages.length} onClick={() => setExpanded(true)}/>
        )

      }
      {visibleMessages.map(message => <MessageListItem message={message} key={`message_${message.id}`}/>)}
    </Box>
  )
}

MessageList.propTypes = {
  messages: PropTypes.array
}

export default React.memo(MessageList)