import React from 'react'
import PropTypes from "prop-types"

import { Box, Text, TextInput } from 'grommet'

const InputField = ({ label, error, margin, ...rest }) => {
  return (
    <Box
      margin={margin}>
      {label && (
        <Text margin={{ bottom: "small" }}>{label}</Text>
      )}
      <TextInput
        {...rest}
      />
      {error && (
        <Text 
          size="small"
          color="status-error"
          margin={{ top: "small" }}>{error}</Text>
      )}
    </Box>
  )
}

InputField.defaultProps = {
  margin: { vertical: "medium" },
  type: 'text'
}

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default InputField