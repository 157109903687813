import React from "react"

import AppWrapper from "../components/App/AppWrapper"
import NothingHere from "../components/Common/NothingHere"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <AppWrapper>
      <NothingHere
        title={t("NotFound.NothingHere")}
        message={t("NotFound.NotWhatYouAreLookingFor")}
      />
    </AppWrapper>
  )
}

export default NotFoundPage
