import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Box, DropButton, Text, Button } from "grommet"
import { useTranslation } from "react-i18next"
import { useUsers } from "../../hooks/users"

const UserBox = styled(Box)`
  &:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }
`

const AddFollowersForm = ({ onSubmit, omit }) => {
  const { t } = useTranslation()
  const users = useUsers()

  const potentials = (users.data || []).filter(u => omit.indexOf(u.partner_id) < 0)

  return potentials.length > 0 ? (
    <Box margin={{ vertical: "small" }}>
      <DropButton
        label={t("Ticket.AddFollowers")}
        dropAlign={{ top: "bottom", left: "left" }}
        dropContent={
          <Box>
            {potentials.map(u => (
              <Button plain key={u.id}>
                <UserBox
                  pad={{ vertical: "small", horizontal: "small" }}
                  key={`u_${u.id}`}
                  onClick={() => onSubmit(u.partner_id)}
                >
                  <Text size="small">{u.name}</Text>
                  <Text size="xsmall" color="grey">
                    {u.email}
                  </Text>
                </UserBox>
              </Button>
            ))}
          </Box>
        }
      />
    </Box>
  ) : null
}

AddFollowersForm.propTypes = {
  onSubmit: PropTypes.func,
  omit: PropTypes.array
}

export default React.memo(AddFollowersForm)
