import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Box, ResponsiveContext } from "grommet"
import LinkAnchor from "../Common/LinkAnchor"
import {
  getRoute,
  HOME,
  QUEUE_TICKETS,
} from "../../navigation/routes"
import { useTranslation } from "react-i18next"
import { useCurrentQueue } from "../../hooks/queues"
import { useTicket } from "../../hooks/tickets"

const Divider = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`
const Breadcrums = ({ ticketId }) => {
  const { t } = useTranslation()

  const ticket = useTicket(ticketId)

  const queue = useCurrentQueue()

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box direction="row">
          {size !== "small" && (
            <LinkAnchor to={HOME}>{t("Queue.Helpdesk")}</LinkAnchor>
          )}

          {queue ? (
            <>
              {size !== "small" && <Divider>/</Divider>}
              <LinkAnchor
                to={getRoute(
                  QUEUE_TICKETS,
                  { queueId: queue.slug }
                )}
              >
                {queue.name}
              </LinkAnchor>
            </>
          ) : null}

          {ticket ? (
            <>
              <Divider>/</Divider>
              {ticket.code}
            </>
          ) : null}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

Breadcrums.propTypes = {
  queueId: PropTypes.string,
  ticketId: PropTypes.number,
}

export default React.memo(Breadcrums)
