import React from "react"
import { Box, Text } from "grommet"

const MessagesExpander = ({ length, onClick }) => {
  return (
    <Box
      pad={{ vertical: "small" }}
      margin={{ bottom: "medium" }}
      style={{ cursor: "pointer" }}
    >
      <Box pad={{ vertical: "small" }} align="center">
        <Text onClick={onClick}>Mostra altri {length} messaggi più vecchi</Text>
      </Box>
    </Box>
  )
}

export default React.memo(MessagesExpander)
