import React, { useState } from "react"
import { Box, CheckBoxGroup, Drop, Text } from "grommet"
import { CaretDownFill } from "grommet-icons"

function CheckboxSelectField({ label, options, onChange, value }) {
  const [open, setOpen] = useState(false)
  const [containerRef, setContainerRef] = useState(null)

  const handleChange = (ev) => {
    if (ev.value.length) {
      onChange(ev.value)
    } else {
      onChange(undefined)
    }
  }

  return (
    <Box ref={setContainerRef}>
      <Box
        onClick={() => setOpen(true)}
        pad="small"
        background="light-2"
        direction="row"
        align="center"
        gap="xsmall"
      >
        <Text size="small">{label}</Text>
        <CaretDownFill />
      </Box>
      {open && containerRef && (
        <Drop
          onClickOutside={() => setOpen(false)}
          onEsc={() => setOpen(false)}
          align={{ top: "bottom", right: "right" }}
          target={containerRef}
        >
          <Box pad="medium" elevation="medium">
            <CheckBoxGroup
              options={options}
              onChange={handleChange}
              labelKey="label"
              valueKey="value"
              size="small"
              value={value}
            />
          </Box>
        </Drop>
      )}
    </Box>
  )
}

export default CheckboxSelectField
