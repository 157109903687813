import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Heading, Paragraph, Box } from 'grommet'

const SubtitleText = styled(Paragraph)`
  font-weight: 300
`

const PageTitle = ({title, children, margin = {top:"medium", bottom:"none", horizontal: "none"}}) => {
  return (
    <Box>
      <Heading 
        level="2"
        margin={margin}>
        {title}
      </Heading>
      {children ? (

        <SubtitleText 
          size="xlarge"
          level="3s"
          fill
          margin={{vertical:"small", horizontal: "none"}}
          >{children}</SubtitleText>
      ) : null}
    </Box>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
}

export default React.memo(PageTitle)