import _keyBy from 'lodash/keyBy'
import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { TICKET_ATTACHMENTS, TICKET_DETAILS } from '../../navigation/routes'
import { Box, Keyboard } from 'grommet'
import { CaretPrevious, CaretNext } from 'grommet-icons'
import AttachmentNavbar from '../../components/Attachments/AttachmentNavbar'
import AttachmentPreview from '../../components/Attachments/AttachmentPreview'
import { useAttachments } from '../../hooks/attachments'
import { useRouter } from '../../hooks/router'

const AttachmentPageWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  color: #fff;
`

const ArrowBox = styled(Box)`
  position: absolute;
  top: 50%;
  cursor: pointer;
`
const LeftArrowBox = styled(ArrowBox)`
  left: 20px;
`

const RightArrowBox = styled(ArrowBox)`
  right: 20px;
`

const AttachmentsPage = () => {
  const router = useRouter()
  const { queueId, ticketId, attachmentId } = useParams()
  const attachments = useAttachments(ticketId)
  const current = _keyBy(attachments.data || [], 'id')[attachmentId]

  if(!attachments.data) return null
  
  const attachmentIds = attachments.data.map(a => a.id)
  const current_id_idx = attachmentIds.indexOf(current.id);
  const prev_idx = current_id_idx - 1 < 0 ?  null : current_id_idx - 1;
  const next_idx = current_id_idx +1 === attachments.data.length ? null : current_id_idx + 1;

  const goToAttachment = (dir) => {
    let dest = null

    if(dir === 'next' && next_idx !== null) {
      dest = attachments.data[next_idx]?.id
    }

    if(dir === 'prev' && prev_idx !== null) {
      dest = attachments.data[prev_idx]?.id
    }

    if(dest) {
      router.push(TICKET_ATTACHMENTS, { queueId, ticketId, attachmentId: dest})
    }
  }

  return current && (
    <Keyboard 
      target="document"
      onEsc={() => router.push(TICKET_DETAILS, { queueId, ticketId })}>

      <AttachmentPageWrapper>
        {prev_idx !== null && <LeftArrowBox onClick={() => goToAttachment('prev')}>
          <CaretPrevious size="large"/>
        </LeftArrowBox>}
        {next_idx !== null && <RightArrowBox onClick={() => goToAttachment('next')}>
          <CaretNext size="large"/>
        </RightArrowBox>}
        <AttachmentNavbar attachment={current}/>
        <AttachmentPreview attachment={current}/>
      </AttachmentPageWrapper>
    </Keyboard>
  )
}

export default React.memo(AttachmentsPage)