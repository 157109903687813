import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { setAutoFreeze } from "immer"
import { initializeLocales } from "./locales"

import AppRouter from "./navigation"
import AppContainer from "./components/App/AppContainer"

import "./App.css"
import { QueryClient, QueryClientProvider } from "react-query"
import { AuthProvider } from "./hooks/auth"
import { TicketFiltersProvider } from "./hooks/tickets"

initializeLocales()
setAutoFreeze(false)

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <TicketFiltersProvider>
            <AppContainer>
              <AppRouter />
            </AppContainer>
          </TicketFiltersProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  )
}

export default App
