import React from 'react'
import styled from 'styled-components'
import { QUEUE_DETAIL } from '../../navigation/routes'
import PropTypes from 'prop-types'
import { Box, Heading, Paragraph } from 'grommet'
import { useRouter } from '../../hooks/router'

const QueueBox = styled(Box)`
  cursor: pointer
`
const QueueAvatar = styled.img`
  width: 100%
`

const QueueListItem = ({ queue }) => {
  const router = useRouter()
  const goToQueue = () => router.push(QUEUE_DETAIL, {queueId: queue.slug})
  

  return (
    <QueueBox 
      elevation="medium" 
      margin="medium"
      width="medium"
      pad="small"
      justify="center"
      direction="row"
      onClick={goToQueue}
      >
      {queue.image ? (
        <Box
          width="xsmall" 
          pad={{top:"medium", left: "small"}}
          align="center">
          <QueueAvatar src={queue.image}/>
        </Box>
      ) : null}
      <Box
        fill
      pad="medium"
      >
        <Heading  level="3" margin="none">
          {queue.name}
        </Heading>
        <Box fill>

          <Paragraph size="small" margin={{vertical: "small"}}>
            {queue.description}
          </Paragraph>
        </Box>
      </Box>
    </QueueBox>
  )
}

QueueListItem.propTypes = {
  queue: PropTypes.object
}

export default React.memo(QueueListItem)