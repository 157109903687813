import React, { useState, useMemo, createContext } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import api from "../data/network"
import { useRouter } from "./router"

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const contextValue = useMemo(() => ({ user, setUser }), [user, setUser])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export function useAuth() {
  const [initialized, setInitialized] = useState(false)
  const authContext = React.useContext(AuthContext)
  const router = useRouter()
  const queryClient = useQueryClient()

  if (authContext === undefined) {
    throw new Error("You must wrap useAuth with an AuthProvider")
  }

  const query = useQuery(["user"], api.userInfo, {
    onSuccess: (data) => {
      authContext.setUser(data)
    },
    onSettled: () => {
      setInitialized(true)
    },
    // enabled: !!api.getToken(),
    onError: () => {},
    
    retry: false,
  })

  const login = useMutation(
    ({ email, password }) => api.login(email, password),
    {
      onSuccess: (data, variables, contexxt) => {
        authContext.setUser(data)
        if(variables.next) {
          router.push(variables.next)
        } else {
          router.push('/')
        }
      },
    }
  )

  function logout() {
    api.logout()
    queryClient.invalidateQueries("user")
    authContext.setUser(null)
    router.push("/login")
  }

  return {
    isLoading: query.isLoading,
    user: authContext.user,
    initialized,
    login,
    logout,
  }
}

