import React from 'react'
import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { useQueryParam, StringParam } from 'use-query-params';

import LoginForm from '../../components/Users/LoginForm'
import LineHeading from '../../components/Common/LineHeading'
import { Box, Button, Image, Text } from 'grommet'
import logo from '../../assets/images/logo.png'
import { useAuth } from '../../hooks/auth'

const LoginBackground = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fdfdfd;
`

const LoginPage = () => {
  const { login } = useAuth()
  const [error] = useQueryParam('error', StringParam);

  const { t } = useTranslation()

  function handleLogin(values) {
    login.mutate(values)
  }

  return (
    <LoginBackground>
      <Box margin="medium">
        <Image width={180} src={logo} margin={{ bottom: "medium" }} />
      </Box>

      <Box align="center">
        <Box width="medium">
          <Box align="center" margin={{bottom: "medium"}}>
            
            <LineHeading level='2' margin={{ bottom: "medium" }}>{t('Login.Login')}</LineHeading>
            <Text textAlign="center" size="small" color="gray">
              {t('Login.WelcomMessage')}
              </Text>
          </Box>
          <LoginForm
            submitting={login.isLoading}
            onSubmit={handleLogin} />

          {(login.error || error) &&  (
            <Text
              alignSelf="center"
              color="status-error"
              margin={{ top: "small" }}>
              {t("Invalid email or password")}
            </Text >
          )}


        </Box>
        <Box margin={{top:"medium"}}>
          <Button pad="small" href='https://accounts.metadonors.it/oauth/authorize/?client_id=UDkjbDXr7FNUgudU6uud0TSIScKsVaSxjxOCgRHG&redirect_uri=https://helpdesk.metadonors.it/oauth&response_type=code'>
            {t("Login.LoginWithMetadonors")}
          </Button>

        </Box>
      </Box>

    </LoginBackground>
  )
}

export default React.memo(LoginPage);
