import _keyBy from "lodash/keyBy"
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Box, Text } from "grommet"
import parse from "html-react-parser"
import AttachmentList from "../Attachments/AttachmentList"
import { formatDatetime } from "../../utils/date"
import Avatar from "react-avatar"
import { useTranslation } from "react-i18next"
import { useAttachments } from "../../hooks/attachments"

const InternalLabel = styled(Text)`
  text-transform: uppercase;
`

const MessageListItem = ({ message }) => {
  const { t } = useTranslation()
  const attachments = useAttachments(message.ticket_id)
  const attachmnentsById = _keyBy(attachments.data || [], 'id')
  const messageAttachments = message.attachment_ids
    .map(id => attachmnentsById[id])
    .filter(a => a)

  const renderContent = () => {
    return (
      <Box>
        <Box direction="row" gap="small" align="center">
          <Avatar
            size="30px"
            round
            email={message.author_email}
            src={message.author_image}
            name={message.author_name}
          />
          <Text size="small" color="grey2">
            <b>{message.author_name}</b> Il{" "}
            <b>{formatDatetime(message.date)}</b> dice:
          </Text>
          {message.internal && (
            <Box
              background="brand"
              pad={{ horizontal: "small", vertical: "xsmall" }}
            >
              <InternalLabel size="xsmall" weight="bold" color="#fff">
                {t('Message.Support').toUpperCase()}
              </InternalLabel>
            </Box>
          )}
        </Box>

        <Box
          pad={{ horizontal: "small" }}
          round="small"
          margin={{ top: "small" }}
          background={message.internal ? "#f7f7f7" : "none"}
        >
          {parse(message.body)}
        </Box>

        <AttachmentList attachments={messageAttachments} size="xsmall" />
      </Box>
    )
  }

  return (
    <Box pad="small" margin={{ bottom: "small" }}>
      {renderContent()}
    </Box>
  )
}

MessageListItem.propTypes = {
  message: PropTypes.object
}

export default React.memo(MessageListItem)
