import _debounce from "lodash/debounce"
import _isEqual from "lodash/isEqual"
import React, { useEffect, useState, useCallback } from "react"

import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Box, TextInput } from "grommet"
import CheckboxSelectField from "../../Forms/CheckboxSelectField"
import { useCurrentQueue } from "../../../hooks/queues"
import { useUsers } from "../../../hooks/users"
import StageSelector from "./StageSelector"
import { Search } from 'grommet-icons'

function FilterBar({ filters, onChange }) {
  const { t } = useTranslation()
  const users = useUsers()
  const queue = useCurrentQueue()

  const [currentValues, setCurrentValues] = useState(filters)

  const handleFormChanges = useCallback(
    _debounce((values) => {
      if (!_isEqual(values, filters)) {
        onChange(values)
      }
    }, 200),
    [onChange]
  )

  useEffect(() => {
    handleFormChanges(currentValues)
  }, [currentValues, handleFormChanges])

  return (
    <Formik
      enableReinitialize
      initialValues={filters}
      render={({ values, handleBlur, handleChange, handleSubmit }) => {
        setCurrentValues(values)
        return (
          <Box direction="row" gap="small">
            <Box direction="row" flex="grow">
              <Box flex="grow">
                <TextInput
                  name="query"
                  icon={<Search/>}
                  placeholder={t("Ticket.SearchInTickets")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.query || ""}
                  size="small"
                />
              </Box>
            </Box>

            <Box direction="row">
              <StageSelector
                queue={queue}
                onChange={handleChange("state")}
                value={values.state}
              />
              {users.data && <CheckboxSelectField
                name="created_by"
                label={t("Ticket.CreatedBy")}
                options={users.data.map((u) => ({
                  value: u.partner_id.toString(),
                  label: u.name,
                }))}
                onChange={handleChange("created_by")}
                value={values.created_by}
              />}
            </Box>

            {/* <Button
              size="small"
              radi
              primary
              color="light-3"
              label="Salva filtri"
            /> */}
          </Box>
        )
      }}
    />
  )
}

export default FilterBar
