import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Box, Text, Drop } from "grommet"
import { useQueuesById } from "../../../hooks/queues"

const StatusBox = styled(Box)`
  cursor: pointer;
`

const StageBox = styled(Box)`
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }
`

const TicketStatusForm = ({ current, ticket, onChange, loading }) => {
  const [open, setOpen] = React.useState(false)
  const [target, setTarget] = React.useState()
  const targetRef = React.useCallback(setTarget, [])
  const queue = useQueuesById()[ticket.queue_id]

  const handleChange = (stage) => {
    onChange(stage)
    setOpen(false)
  }

  return (
    <Box ref={targetRef}>
      <StatusBox
        pad="small"
        background={loading ? "grey" : "brand"}
        onClick={() => setOpen(!open)}
      >
        <Text size="large">{current}</Text>
      </StatusBox>

      {open && target && queue && (
        <Drop
          onClickOutside={() => setOpen(false)}
          onEsc={() => setOpen(false)}
          align={{ top: "bottom", left: "left" }}
          target={target}
        >
          {queue.stage_ids
            .filter((s) => s.name !== current)
            .map((s) => (
              <StageBox
                onClick={() => handleChange(s)}
                pad="medium"
                key={`stage_${s.id}`}
              >
                <Text>{s.name}</Text>
              </StageBox>
            ))}
        </Drop>
      )}
    </Box>
  )
}

TicketStatusForm.propTypes = {
  current: PropTypes.string,
  ticket: PropTypes.object,
  onChange: PropTypes.func,
}

export default React.memo(TicketStatusForm)
