import React from 'react'
import { StatusGoodSmall } from 'grommet-icons'

const COLORS = {
  '0': 'status-unknown',
  '1': 'status-warning',
  '2': 'status-critical',
  '3': 'neutral-4',
}

const PriorityIcon = ({ priority }) => {
  return (
    <StatusGoodSmall
      color={COLORS[priority]}/>
  )
}

export default React.memo(PriorityIcon)