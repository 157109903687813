import React from "react"
import { Box } from "grommet"
import { useTranslation } from "react-i18next"

import LinedTitle from "../Common/LinedTitle"
import MessageList from "./MessageList"
import MessageForm from "./MessageForm"
import { useMessages, useMessagesMutations } from "../../hooks/messages"

const MessageBox = ({ ticket }) => {
  const { t } = useTranslation()
  const messages = useMessages(ticket.id)
  const { add } = useMessagesMutations()

  function handleSubmitMessage(payload) {
    add.mutate(payload)
  }

  return (
    <Box>
      <LinedTitle title={t('Message.Discussion')} />
      {!messages.isLoading && messages.data.length > 0 ? (
        <Box animation="fadeIn">
          <MessageList messages={messages.data} />
        </Box>
      ) : null}
      <MessageForm ticketId={ticket.id} onSubmit={handleSubmitMessage} isLoading={add.isLoading} />
    </Box>
  )
}

export default React.memo(MessageBox)
