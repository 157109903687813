import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Text, Button } from 'grommet'
import { DocumentCsv, Close, DocumentExcel, DocumentPdf, Document } from 'grommet-icons'

const PreviewImage = styled.img`
  width: 100%;
  height: auto;
`

const PreviewBox = styled(Box)`
  border: 1px solid #cdcdcd
`

const AttachmentFilePreviewBox = styled(Box)`
  position: relative
`

const RemoveBox = styled(Box)`
  position: absolute;
  right: 8px;
  top: 8px;
`

const AttachmentFilePreview = ({ file, onDelete = null }) => {


  const renderPreview = () => {
    if (file.type.indexOf('image') >= 0) {
      return <PreviewImage src={URL.createObjectURL(file)} />
    } else if (file.type.indexOf('pdf') >= 0) {
      return <DocumentPdf size="large" />
    } else if (file.type.indexOf('excel') >= 0) {
      return <DocumentExcel size="large" />
    } else if (file.type.indexOf('csv') >= 0) {
      return <DocumentCsv size="large" />
    } else {
      return <Document size="large" />
    }
  }

  return (
    <AttachmentFilePreviewBox
      basis="small"
      margin={{ right: "small", bottom: "small" }}>
      {onDelete ? (
        <RemoveBox>
          <Button plain icon={<Close />} onClick={() => onDelete(file)} />
        </RemoveBox>
      ) : null}

      <PreviewBox
        overflow="hidden"
        justify="center"
        align="center"
        height="small"
        width="small">
        {renderPreview()}
      </PreviewBox>
      <Text
        margin={{ vertical: "small" }}
        truncate
        size="small">{file.name}</Text>
    </AttachmentFilePreviewBox>
  )
}

AttachmentFilePreview.propTypes = {
  file: PropTypes.object,
  onDelete: PropTypes.func
}

export default React.memo(AttachmentFilePreview)