import React from "react"
import PropTypes from "prop-types"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import { Box, Text } from "grommet"
import AttachmentPreview from "./Attachments/AttachmentFilePreview"
import _uniqBy from "lodash/uniqBy"
import { useTranslation } from "react-i18next"

const DropzoneBox = styled(Box)`
  border: 3px dashed rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    border: 3px solid rgba(0, 0, 0, 0.2);
    background-color: #fdfdfd;
    color: rgba(0, 0, 0, 0.8);
  }
`

const AttachmentsField = ({ value, onChange }) => {
  const { t } = useTranslation()

  const onDrop = React.useCallback(
    acceptedFiles => {
      onChange(_uniqBy([...acceptedFiles, ...value], e => e.path))
    },
    [onChange, value]
  )

  const onDelete = file => onChange(value.filter(f => f.path !== file.path))

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <Box margin={{ vertical: "none" }}>
      <DropzoneBox
        margin={{ vertical: "medium" }}
        gap="medium"
        pad={{ horizontal: "medium" }}
        align="center"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text>{t("Attachment.DragAnAttachmentHere")}</Text>
        ) : (
          <Text>{t("Attachment.YouCanDragAnAttachmentHereOrClick")}</Text>
        )}
        <Box direction="row" wrap>
          {value.map(attachment => (
            <AttachmentPreview
              key={attachment.path}
              file={attachment}
              onDelete={onDelete}
            />
          ))}
        </Box>
      </DropzoneBox>
    </Box>
  )
}

AttachmentsField.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func
}

export default React.memo(AttachmentsField)
